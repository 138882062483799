html {
    scroll-behavior: smooth;
}

*,
::after,
::before {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #000;
    padding-right: 0!important;
}

.toastContainer {
    z-index: 2000;
}

@font-face {
    font-family: "poppins";
    src: local("poppins"), url(./assets/fonts/Poppins.ttf) format("truetype");
}
@font-face {
    font-family: "bold-poppins";
    src: local("bold-poppins"),
        url(./assets/fonts/Poppins-Bold.ttf) format("truetype");
}
@font-face {
    font-family: "semi-poppins";
    src: local("semi-poppins"),
        url(./assets/fonts/Poppins-SemiBold.ttf) format("truetype");
}

@font-face {
    font-family: "roboto";
    src: local("roboto"),
        url(./assets/fonts/Roboto-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "roboto-bold";
    src: local("roboto-bold"),
        url(./assets/fonts/Roboto-Bold.ttf) format("truetype");
}
